import rawTimeZones from '@vvo/tzdb/raw-time-zones.json';

const timeZones = rawTimeZones.map((tz) => ({
  label: tz.rawFormat,
  value: tz.name,
}));

document.addEventListener('DOMContentLoaded', function () {
  const timeZoneSelector = document.querySelector('#user_time_zone');

  const browserDetectedTimezone =
    Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York';

  if (timeZoneSelector) {
    timeZoneSelector.innerHTML = '';

    let fragment = document.createDocumentFragment();
    timeZones.forEach((tz) => {
      let opt = document.createElement('option');
      opt.innerHTML = tz.label;
      opt.value = tz.value;
      fragment.appendChild(opt);
    });
    timeZoneSelector.appendChild(fragment);
    const userTimeZone = timeZoneSelector.dataset.userTimeZone;
    timeZoneSelector.value = userTimeZone || browserDetectedTimezone;
  } else {
    document.getElementById('user_time_zone_signin').value =
      browserDetectedTimezone;
  }
});
